import Logo from '../assets/kinsta-logo.png' // eslint-disable-line no-unused-vars

const Header = () => {
    return (
        <>
            <h1 
                className="title"
            >
                いろどり夢工房
            </h1>
        </>
        // <img src={Logo} alt="Kinsta Logo" className="kinsta-logo" />
    )
}

export default Header
