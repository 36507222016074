import { Form, Footer, Header } from './components';
import preview from './assets/preview.png';
import Loader from './assets/loader.gif'
import { downloadImage } from './utils';

import { useState } from 'react';

const App = () => {
	// const [isLoading, setIsLoading] = useState(false)
	const [recommend, setRecommend]=useState('');
	const [isGenerating, setIsGenerating] = useState(false);
	const [generatedImage, setGeneratedImage] = useState({
		photo: null,
		altText: null,
	});

	//画像生成のリクエスト
	const generateImage = async (prompt) => {
		if (prompt) {
			try {
				// 画像生成
				setIsGenerating(true);
				const response = await fetch(
					'/api/GenerateImage',
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							prompt,
						}),
					}
				);

				const data = await response.json();
				setGeneratedImage({
					photo: data.url,
					altText: prompt,
				});

				console.log(data.url);

				return data.url; 
			} catch (err) {
				alert(err);
				setIsGenerating(false);
			} finally {
				// setPrompt('');
				setIsGenerating(false);
			}
		} else {
			alert('Please provide proper prompt');
		}
	};

	//ショップおすすめのリクエスト
	const recommendShops = async(url) => {
		console.log('url: ' + url);
		try{
			setIsGenerating(true);
			const response= await fetch(
				'/api/GenerateDescription',
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: 
					JSON.stringify({
						url,
					}),
				}
			)
	
			const data = await response.json();

			console.log(data.txtRecommend);
	
			return data.txtRecommend;
		}catch(err){
			alert(err);
		} finally {
			// setPrompt('');
			setIsGenerating(false);
		}
		
	}

	return (
		<div className='container'>
			<Header />
			<main className="flex-container-top">
				<Form generateImage={generateImage} prompt={prompt} setRecommend={setRecommend} recommendShops={recommendShops}/>
				<div className="image-container">
					{generatedImage.photo ? (
						<img
							src={generatedImage.photo}
							alt={generatedImage.altText}
							className="imgg ai-img"
						/>
					) : (
						<img
							src={preview}
							alt="preview"
							className="imgg preview-img"
						/>
					)}

					{isGenerating && (
						<div className="loader-comp">
							<img src={Loader} alt="" className='loader-img' />
						</div>
					)}
					{/* <div className='flex-container'> */}
					<textarea
						placeholder='recommend shops...'
						readOnly={true}
						value={recommend}
					>
					</textarea>
					<button
						className="btn"
						onClick={() => downloadImage(generatedImage.photo)}
					>
						Download
					</button>
					{/* </div> */}				
				</div>
			</main>
			<Footer />
		</div>
	);
};

export default App;
