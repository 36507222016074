import { useState, useRef } from 'react'; // eslint-disable-line no-unused-vars

const Form = ({ generateImage, setRecommend, recommendShops}) => {

    // #Values

    // prompt
    // const [prompt, setPrompt]=useState('');

    // 部屋の広さ
    const [area, setArea] = useState(1);

    // 部屋タイプ（洋室、和室）
    const roomTypes=[
        '洋室',
        '和室',
    ]

    // 選択された部屋の雰囲気
    const [selectedRoomType,setSelectedRoomType]=useState('洋室');

    // 部屋の雰囲気の選択肢
    const atomospheres=[
        '未選択',
        'かわいい',
        'かっこいい',
        'シンプルな',
        'ナチュラルな',
        'アメリカンな',
        'カフェ風の',
        '北欧風の',
        '和モダンの',
    ]

    // 選択された部屋の雰囲気
    const [selectedAtomosphere,setSelectedAtomosphere]=useState('');

    // その他の好みテキストボックス
    const[txtPrefarense, setPrefarense]=useState('');

    // // その他の好みを保存しておく変数
    // const[prefarenses, setPrefarenses]=useState([]);
    // const refPrefarenses=useRef([]);

    // #endofvalues

    // #functions

    // 雰囲気コンボボックスが変わったとき、stateに値格納
    const handleChangeRoomType=(targetValue)=>{
        setSelectedRoomType(targetValue);
    }

    // 雰囲気コンボボックスが変わったとき、stateに値格納
    const handleChangeAtomosphere=(targetValue)=>{
        setSelectedAtomosphere(targetValue);
    }

    // GenerateButton押下時の処理
    const handleClickGenerateImage= async () => {
       // 画像生成
       const url = await generateImage(createPrompt());

       const recommend= await recommendShops(url);

       // ショップのレコメンド
       setRecommend(recommend);
    }

    // 好みの配列にテキストボックスの内容を追加する処理
    // const addPreferenses=()=>{
    //     setPrefarenses([...prefarenses,txtPrefarense]);

    //     // TODO: 後で消す
    //     alert(`${txtPrefarense}を追加しました`);
    // }

    // 入力内容からpromptを作成
    const createPrompt=()=>{

        let pr='';

        // 広さ+部屋タイプ
        const squareMeter=area*1.824;
        if(selectedRoomType==='洋室'){
            
            pr+=`${squareMeter}平米くらいの洋室のインテリアコーディネート`;
        }else{
            pr+=`${squareMeter}平米くらいの和室のインテリアコーディネート`;
        }
        
        // 雰囲気
        if(selectedAtomosphere!=='未選択'||selectedAtomosphere!==''){
            pr+=`で${selectedAtomosphere}雰囲気`;
        }

        pr+='をリアルな写真風で。'

        // その他の好み
        if(txtPrefarense!==''){
            pr+=`${txtPrefarense}が好きです。`;
        }

        // TODO: 後で消す
        console.log(pr);

        return pr;

    }


    return (
        <div className="form-section">
            <div className="container-title">
                <h3>コーディネートしたい部屋の条件を入力してください</h3>
                <p className="description">
                    {/* Ignite your imagination using the power of Artificial Intelligence (AI). Craft mesmerizing images through vivid text descriptions. */}
                </p>
            </div>
            <div className="form-container">
                <div className="input-div">
                    {/* <span>Type a very descriptive text of the image you wish to generate.</span> */}
                    <div className='margin-bottom'>
                    <label>部屋の広さ</label>
                    <div className="input-cont">
                        <div className='flex-container'>
                            <input
                             name='area'
                             min={1}
                             value={area}
                             defaultValue={1}
                             onChange={(e)=>setArea(e.target.value)}
                             type='number'
                             placeholder='area'>
                            </input>
                            <label>畳</label>
                        </div>
                    </div>
                    </div>
                    <div className='margin-bottom'>
                    <label>洋室or和室</label>
                        <select
                        onChange={(e)=>handleChangeRoomType(e.target.value)}>
                            {roomTypes.map((roomType)=>{
                                return <option key={roomType}>{roomType}</option>
                            })}
                        </select>
                    </div>
                    <div className='margin-bottom'>
                    <label>部屋の雰囲気</label>
                        <select
                        onChange={(e)=>handleChangeAtomosphere(e.target.value)}>
                            {atomospheres.map((atomosphere)=>{
                                return <option key={atomosphere}>{atomosphere}</option>
                            })}
                        </select>
                    </div>
                    <label>その他の好みがあれば追加してください</label>
                    <div className='flex-container'>
                        <input 
                        name='prefarense'
                        id=""  
                        value={txtPrefarense} 
                        onChange={(e) => setPrefarense(e.target.value)} 
                        className="form-control-flex" 
                        placeholder="What's do You like?">
                        </input>
                        <label>
                            が好き
                        </label>
                    </div>
                </div>
                <button 
                className='btn' 
                onClick={() => handleClickGenerateImage()}>
                    Generate Image
                </button>
            </div>
        </div>
    )
}


export default Form


// TODO: 
// ボタンとコンボボックスにCSS当てる
// addされたラベルの内容を可視化する
// 金田さんが作ったpromptと合成する
